@import '../../assets/css/_colors.scss';
@import '../../assets/css/_responsive';


div.sticky-banner-container {
  width: 100vw;
  position: fixed;
  left: 0;
  background-color: #fff;
  border-top: 4px solid var(--primary-color);
  z-index: 1;
  padding: 26px 0;
  div.content {
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div.text {
      text-align: center;
      a {
        color: var(--primary-color);
      }
    }
    div.close {
      height: 16px;
      width: 16px;
      cursor: pointer;
      img {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: $layout-breakpoint-sm) {
  div.sticky-banner-container {
      div.content {
        flex-direction: column;
        align-items: center;
        div.text {
          margin: 30px 0;
          text-align: center;
        }
        div.helpi-button-container {
          text-align: left;
        }
      }
  }
}

@media (max-width: $layout-breakpoint-xs) {
  div.sticky-banner-container {
    div.content {
      div.text {
        padding: 0 15px;
        margin: 15px 0;
        white-space: normal;
      }
    }
  }
}