div.forgot-password-modal {
  section[role='body']{
    > div:not(.modal-header) {
      width: 320px;
      min-height: calc(350px - 64px);
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      padding: 15px;
      div.validated-input-container {
        width: 180px;
        label.description-label {
          font-size: 11px;
        }
      }
      div.helpi-button-container {
        display: flex;
        justify-content: center;
      }
      div.success-message {
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
  }
}