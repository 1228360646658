@import "../../assets/css/responsive";
@import "../../assets/css/variables";
@import "../../assets/css/colors";

.header-container {
  height: 83px;
  display: flex;
  align-items: center;
  div.donors {
    color: #ffffff;
    font-size: 14px;
    padding: 10px;
    font-weight: 500;
    background-color: var(--secondary-color);
    border-radius: 2px;
    top: 2px;

  }
  //&.strauss {
  //  div.header-content.whitelabel .nav-bar {
  //    padding-right: calc(288px + 150px);
  //  }
  //}

  //&:not(.whitelabel) {
  //  a.logo {
  //    margin-top: 20px;
  //  }
  //}

  div.auth-options {
    border:1px solid red;
  }
  div.header-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .nav-bar {
      padding-bottom: 10px;
    }
    &.whitelabel {
      .nav-bar {
        padding-top: 15px;
        //padding-right: 288px;
        li:not(.sub-menu-item):not(.ask-help) {
          margin-left: 10px;
          //min-width: 60px;
        }
        .sub-menu-item:last-child {
          border-bottom: 0;
        }
      }
      }
    }
  &:not(.home-route) {
      background-color: var(--heading-bg-color);
    .login-profile-wrapper div.user-dropdown ul.list-wrapper,
    .nav-bar .sub-menu {
      background-color: var(--heading-bg-color);
      border-radius: 0 0 3px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 0 rgba(0, 0, 0, 0.08);
    }
  }

  &:not(.login-route) {
    a.logo {
      //background-image: var(--header-logo);
    }
  }

  //background-color: $header-bg;
  width: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: row;
  line-height: 18px;
  a{
    text-decoration: none;
    color: inherit;
  }
  &.home-route {
    position: absolute;
    background-color: rgba(0,0,0,0);
    box-shadow: none;

    .nav-bar {
      .nav-bar-section {
        div.ask-help {
          border-radius: 3px;
        }

      }

    }
    .sub-menu {
      background-color: rgba(255,255,255,0.8);
      //background-color: rgba(0,0,0,0);
    }
    div.login-profile-wrapper {
      div.user-dropdown {
        ul.list-wrapper {
          background-color: rgba(0,0,0,0);
        }
      }
    }
  }

  &.login-route {
    box-shadow: none;
    a.logo {
      background-image: url("../../assets/img/logo.png");
    }
    div.header-content,
    div.small-nav-bar {
      display: none;
    }
    &.whitelabel {
      div.whitelabel-logo {
        max-width: 110px;
        position: absolute;
        top: 60px;
        right: 50px;
        img {
          max-width: 100%;
        }
      }
    }
  }

  &.transparent {
    background-color: transparent;
  }



  a.logo {
    //background-image: var(--header-logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
    display: inline-flex;
    //margin-top: 25px;
    margin-right: 55px;
    //position: absolute;
    top: 25px;
    right: 25px;
    //height: 60px;
    max-height: 100%;
    width: 132px;
    padding-top: 5px;
    transition: background-image 0.5s ease;
    //display: inline;

    img {
      //width: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    &.white {
      background-image: url("../../assets/img/logo-white.png");
      transition: background-image 0.5s ease;
    }

  }

  div.whitelabel-logo {
    &.strauss {
      img {
        //margin: 0 10px;
        //max-width: 150px;
        max-height: 75px;
      }
    }
    //position: absolute;
    top: 25px;
    right: calc(25px + 140px + 10px);
    //width: 100px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    //margin-top: 10px;
    img {
      width: auto;
      height: auto;
      max-height: 50px;
    }
  }

  .header-title {
    color: var(--menu-link-color);
    font-size: 40px;
    margin-top: 65px;
  }

  .header-subtitle {
    color: var(--menu-link-color);
    font-size: 24px;
    width: 470px;
    text-align: center;
    margin-top: 3em;
  }

  .how-it-works-btn {
    margin-top: 20px;
    padding: 5px 20px;
    background-color: initial;
    font-size: 14px;
    color: var(--menu-link-color);
    border:none;
    background: $admin-tab-active-color-a;
  }

  #header-inner-wrapper {
    height: 225px;
    opacity: 1;
    transition-property: height, opacity;
    transition: all linear 0.1s;
    margin:auto;
    text-align: center;
    &.extra-filters {
      height:280px;
    }
    &.ng-hide {
      height: 0px;
      opacity: 0;
    }
    h1 {
      color: #FFF;
      margin-top: 50px;
    }
  }

  .filter-content {
    color: #ffffff;
    .layout-column {
      >div {
        display: flex;
        > * {
          margin-left: 7px;
          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }
    md-input-container, md-select, md-datepicker {
      background: #ffffff;
      border-radius: 3px;
      color: #000000;
      height: 30px;
    }
    md-input-container, md-select {
      label {
        color:#000000 !important;
        font-size: 12px!important;
        padding-right: 5px!important;

      }
      &.md-input-focused, &.md-input-has-value {
        label {
          display: none;
        }
      }

      max-height: 30px;
    }
    md-select {
      margin:0;
      /*
      padding-right: 5px;
      */
      max-height: 30px;
      overflow: hidden;
      .md-select-value {
        border-bottom-width: 0px;
      }
      .md-select-placeholder {
        color: #000000;
      }
      .md-select-value *:first-child {
        display:flex;
      }
    }
    input, .md-datepicker-input-container {
      border-width: 0px!important;
    }
    .md-datepicker-input-container {
      width: 141px;
    }
    select-box,input-box{
      width: 190px;
      >*{
        width: 190px;
        min-width:initial;
      }
    }

    md-datepicker {
      display: inline-block;
      padding:0px 0px 3px 0px;
      margin-right: 0px;
      margin-left: 5px;

      .md-icon-button {
        padding: 0px;
      }
      md-icon {
        margin-top: -7px;
      }

    }
  }
  .how-does-it-work-btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #headerFiltersMobile {
    display: none;
  }
  #headerFilters {

    margin-top: 20px;
    text-align: right;

    .gi-filter-buttons {
      margin-right: 3px;
    }

    .date-filter-row {
      margin-top: 10px;
      /*      text-align: right !important;
            padding: 20px 0px 20px 0px;*/
    }

    .date-filter-row.shown {
      display: block;
    }

    .date-filter-row.hidden {
      display: none;
    }

    md-input-container, md-select{
      margin: 0 !important;
      //padding: 1px !important;
    }

    button{
      margin: 0px !important;
      min-height: 34px;
      line-height: 34px;
    }
  }
}

.nav-bar {
  min-width: 600px;
  color: var(--menu-link-color);
  font-size: 13px;
  font-weight: 800;
  padding-right: 55px;
  padding-top: 25px;
  display: flex;
  width: 100%;
  ul{
    list-style: none;
    padding: 0;
  }

  .nav-bar-section {
    position: relative;
    //min-width: 95px;
    margin-left: 30px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    &.ask-help {
      margin-right: auto;
      margin-left: 170px;
    }
    div.ask-help {
      //margin-top: 38px;
      padding: 0;
      border: 1px solid #ffffff;
      font-size: 15px;
      display: flex;
      align-items: center;
      height: 33px;
      a{
        padding: 5px 20px;
      }
      a.active{
        color: inherit
      }
      &.active {
        background-color: initial;
      }
    }

    &.login-btn {
      position: absolute;
      left: -5px;
    }
    &.signup-btn {
      position: absolute;
      left: 95px;
    }
  }

  .nav-bar-main-link {
    padding: 10px 5px;
    //padding: 20px 5px 10px 5px;
    position: relative;
    min-width: 60px;
    outline: 0;
    margin-bottom: 0;
    width: 100%;
    a{
      padding: 10px 0;
      //padding: 45px 0 10px 0;
    }
    a, label{
      line-height: 18px;
      &.active{
        color: var(--primary-color);
        text-decoration: underline;
        font-weight: bold;
      }
    }
    ul.sub-menu{
      //visibility: hidden;
      li.sub-menu-item {
        display: flex;
        padding: 0;
        a {
          width: 100%;
          padding: 5px 0;
          &:hover{
            //color: #F4EEEE;
            color: var(--menu-link-hover-color)
          }
        }
        &:active, &:focus{
          outline: -webkit-focus-ring-color auto 5px;
        }
      }
    }
    &:hover{
      a, label{
        &.active{
          color: inherit;
        }
      }
      //ul.sub-menu{
      //  visibility: visible;
      //}
    }
    &:hover {color: var(--menu-link-hover-color)}
    //&:hover { color: #F4EEEE; }
    //&.menu-open { color: gray; }

    &.active {
      color: var(--primary-color);
      &.menu-open {color: var(--menu-link-color); }

      .sub-menu-item:first-child {
        border: 0;
      }
    }
    &.emphasize {
      background-color: rgba(88, 145, 161, 0.71);
      &.orange {
        background-color: rgba(230, 161, 23, 0.71);

      }
      &:hover { color: var(--menu-link-hover-color); }
      &.menu-open {color: var(--menu-link-color); }

      .sub-menu-item:first-child {
        border: 0;
      }
    }
  }

  .sub-menu {
    position: absolute;
    width: 95px;
    background-color: #565656;
    z-index: 999;
    //parent has 10px bottom padding
    top: calc(100% - 10px);
    right: calc(50% - (95px / 2));
  }

  .sub-menu-item {
    padding: 10px 0px;
    border-bottom: 1px solid var(--menu-link-color);

    &:last-child {
      border-bottom: none;
    }

    &:hover { color: #F4EEEE; }
  }
}

.small-nav-bar {
  display: none;
}


div.side-nav-menu {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  top: $header-mobile-height;
  //width: 100%;
  width: 60vw;
  z-index: 10;
  font-family: 'Yed', 'arial';

  &.show {
    right: 0;
    transition: right 0.5s ease;
  }

  &.hide {
    right: -60vw;
    transition: right 0.5s ease;
  }

  .hamburger-icon {
    background-image: url("../../assets/img/icons/hamburger-icon.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .route-link {
    &.ask-help {
      margin-top: 0px;
      a {
        background-color: rgba(200, 206, 210, 0.6);
      }
    }
  }
}

.side-nav-option {
  border-bottom: 1px solid #D9D9D9;
  width: 100%;
  font-size: 16px;
  height: 42px;
  display: flex;
  align-items: center;
  a,
  div {
    padding: 10px 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: $text-color;
    &.active {
      background-color: $text-color;
      //background-color: rgba(88, 145, 161, 0.71);
      color: #fff;
    }
  }

  //&.active {
  //  background-color: rgba(88, 145, 161, 0.71);
  //  color: #ffffff;
  //}
}

.how-it-works-section{
  padding-top: 30px;
  max-width: 1000px;
  margin: auto;

  img{
    height: 185px;
    width: 256px;
  }

  &.ng-enter, &.ng-leave {
    -webkit-transition: 0.5s linear all;
    -moz-transition: 0.5s linear all;
    -ms-transition: 0.5s linear all;
    -o-transition: 0.5s linear all;
    transition: 0.5s linear all;
  }

  &.ng-leave.ng-leave-active,
  &.ng-enter {
    max-height: 0;
    opacity: 0;
  }

  &.ng-enter.ng-enter-active,
  &.ng-leave {
    max-height: 600px;
    opacity: 1;
  }

  &.ng-leave, &.ng-enter {
    transition: all 350ms linear;
  }
}

.how-it-works-link{
  text-align: center;
  margin-bottom: 15px;

  .orange-bubble {
    margin: 10px auto;
  }

  .subtitle {
    margin: auto;
    width: 230px;
  }
}

@media only screen and (max-width: 1100px) {
  div.nav-bar-section {
    margin-left: 10px !important;
  }
}

@include media-max($layout-breakpoint-sm) {
  .header-container,
  .header-container.whitelabel {
    height: auto;
    background-color: #ffffff;
    webkit-box-shadow: 0 2px 1px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    .header-title {
      font-size: 32px;
    }
    
    .header-content,
    div.login-profile-wrapper {
      display: none;
    }

    &.home-route {
      border-bottom: none;

      &::before {
        height: 100px;
      }

      .small-nav-bar {
        border-bottom: none;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: blue;
        padding-bottom: 5px;
        background-color: transparent;

        &.scrolled {
          background-color: var(--primary-color);
        }

        a.logo {
          margin-right: 0;
        }
      }
    }

    &.login-route {
      div.whitelabel-logo {
        top: 25px;
        right: calc(25px + 140px);
        height: 50px;
        width: 100px;
        position: static;
      }
    }
    div.whitelabel-logo {
      top: 0;
      height: 100%;
      width: auto;
      max-width: 120px;
      left: calc(30px + 110px + 15px);
      right: auto;
      img {
        max-height: 90%;
        max-width: 100%;
      }
    }
    a.logo,
    .small-nav-bar a.logo {
      display: none;
      //background-image: url('./../../assets/img/logo.png');
    }
    .small-nav-bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      //padding-bottom: 20px;
      z-index: 10;
      height: $header-mobile-height;
      background-color: var(--primary-color);
      //background-color: #ffffff;
      padding: 0px 10px;
      border-bottom: 1px solid #444444;
      display: flex;
      align-items: center;
      justify-content: center;
      div.logo-wrap {
        height: 100%;
        // padding-left: 20px;
        > * {
          display: inline-block;
        }
      }
      a.logo {
        margin-top: 5px;
        margin-bottom: 0;
        margin-right: 0;
        background-position: center center;
        width: 110px;
        background-image: var(--header-logo);
        //order: 1;
        //height: 100%;
        height: 90%;
        left: 30px;
        //top: 0;
        top: 5%;
        right: inherit;
        //position: absolute;
      }
      .hamburger-icon {
        background-image: none;
        width: 20px;
        position: absolute;
        right: 15px;
        top: 15px;

        div {
          width: 100%;
          height: 4px;
          background-color: var(--text-color);
          border-radius: 1px;
          margin-bottom: 3px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      //.hamburger-icon {
      //  //background-image: url("../../assets/img/icons/hamburger-icon.png");
      //  background-position: center center;
      //  background-repeat: no-repeat;
      //  background-size: contain;
      //  width: 22px;
      //  height: 22px;
      //  cursor: pointer;
      //  //position: absolute;
      //}
    }
    .nav-bar{
      display: none;
    }

    .header-subtitle {
      margin-top: 5px;
      font-size: 18px;
      width: 270px;
    }
    #header-inner-wrapper h1{
      margin-top: 2em;
    }

    .how-it-works-btn { margin-top: 38px;}
    #header-inner-wrapper {
      height: 480px;
      &.extra-filters {
        height:460px;
      }
    }
  }

  #headerFilters {
    display: none;
  }


  #headerFiltersMobile {

    display: flex !important;
    flex-direction: column;
    .layout-column{
      align-items: center;
      margin:0 auto;
    }
    .filter-content{
      margin: auto;
      //select-box,input-box{
      //	align-self: center;
      //}
    }

    md-input-container, md-select {
      width: 100%;
      margin-top: 15px !important;
    }

    .md-datepicker-input-container {
      width: 125px;
    }
    .gi-filter-buttons {
      text-align: center;
      margin: auto;

      button {
        width: 100%;
        max-width: 180px;
        margin: 15px 0px 0px 0px;
      }
    }

    .gi-datetime-container {
      flex-direction:column;
      width: 100%;
    }

    md-datepicker {
      margin: auto !important;
    }
    input-box {
      margin: 0px !important;
    }

    padding-bottom: 20px;
  }
}

@include media-max($layout-breakpoint-xs) {
  .header-container {
    &.login-route.whitelabel {
      a.logo {
        right: auto;
        width: 83px;
        //20px and 25px from dots and spacing. 45 / 375 is ~ 12%
        left: calc(12vw + (83px / 2));
        //left: 0;
        //left: calc(-100px / 2);
      }
      div.whitelabel-logo {
        width: 100px;
        right: calc((100px / 2));
        //right: auto;
        top: 0;
      }
    }
  }
}

@include media($layout-breakpoint-sm, $layout-breakpoint-md) {
  .nav-bar{
    .route-link {
      margin-left: 22px;
    }

    .logo {
      top: 25px;
      width: 165px;
    }
  }
}

div.md-select-menu-container{
  md-optgroup:first-of-type{
    md-option:first-of-type{
      div{
        font-weight: bold;
      }
    }
  }

  md-optgroup{
    label{
      padding: 10px;
    }
  }

  /*
  Tasks filter
   */
  &#select_container_13{
    md-optgroup{
      md-option{
        height: 35px;
      }
    }
  }

  &:not(#select_container_13) {
    md-optgroup {
      md-option {
        height: 22px;
        div {
          font-size: 10pt;
        }
      }
    }
  }
}

.regions-box {
  md-optgroup{
    &:first-of-type{
      md-option {
        div {
          font-weight: bold;
        }
      }
    }
  }
  md-option {
    height: 22px;
    div {
      font-size: 10pt;
    }
    &.subregion{
      div {
        margin-right: 20px;
      }
    }
    &.subregion{
      div{
        font-size: 8pt;
      }
    }
  }

  md-optgroup {
    label {
      font-size: 10pt;
      padding-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &:nth-child(2){
      label {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1500px) and (min-width: 957px){
  .nav-bar {
    .nav-bar-section{
      //min-width: 100px;
    }
    .sub-menu {
      //top: 65px;
      .sub-menu-item {
        padding: 5px 0;
      }
    }
  }
}


aside[data-react-toolbox="drawer"]{
  top: 40px;
  width: 60%;
}
