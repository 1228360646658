@import "./../../assets/css/colors";
@import "./../../assets/css/responsive";
div.container, ul.container  {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  &.partners-view {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

  .partners-section {
    .partners-description {
      .description-bold {
        font-weight: bold;
        font-size: 20px;
        margin-top: 10px;
      }
      .description {
        display: inline-flex;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1rem;
        max-width: 66%;
        padding: 5px;
      }

    }
  }
  ul.partners-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li.partner {
      height: 170px;
      line-height: 200px;
      text-align: center;
      max-width: 20%;
      width: 20%;
      &.small-logo{
        width: 100px !important;  
        height: 100px !important;
    }
    }

  

    li.partner,
    li.partner > a {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    li.partner img.partner-img,
    li.partner > a img.partner-img {
      margin: auto;
      display: inline-block;
      max-width: 70%;
      max-height: 60%;
      flex-shrink: 0; // for ie11 bug
    }

   
    li.small-logo img.partner-img,
    li.small-logo > a img.partner-img {
      max-width: 60%; // Adjust the max-width for small-logo
    }
  }
  
}


@include media-max($layout-breakpoint-xs) {
  .partners-view {
    ul.partners-container {
      li.partner {
        max-width: 100%;
        width: 100%;
        img.partner-img {
          box-sizing: border-box;
          max-height: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}