div.location-auto-complete-wrapper{
  label.location-label{

  }
  span.indicator{
    color: #4CAF50;
    font-size: 15px;
    &.error{
      color: #D50808;
    }
  }
  div.geosuggest{
    width: 228px;
    position: relative;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 300;
    color: var(--text-color);
    margin-bottom: 13px;
    div.geosuggest__input-wrapper{
      input.geosuggest__input{
        font-family: 'Roboto';
        margin-top: 10px;
        color: var(--text-color);
        border: 1px solid transparent;
        border-radius: 2px 0 0 2px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        height: 32px;
        outline: none;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        margin-left: 12px;
        padding: 0 11px 0 13px;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
    div.geosuggest__suggests-wrapper{
      position: absolute;
      background-color: #fff;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      width: 100%;
      z-index: 1;
      ul.geosuggest__suggests {
        li.geosuggest__item {
          border-bottom: solid 1px rgba(0, 0, 0, 0.3);
          height: 32px;
          overflow: hidden;
          line-height: 2;
          cursor: pointer;
        }
        &.geosuggest__suggests--hidden {
          max-height: 0;
          overflow: hidden;
          border-width: 0;
        }
      }
    }
  }
}