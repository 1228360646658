@import "../../assets/css/_responsive.scss";

div.registration-success-wrapper{
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  div.wrapper {
    width: 100%;
    height: 100%;
    background: url('../../assets/img/login/flags.png'), linear-gradient(to bottom, rgba(117, 229, 246, 0.7) 0%, rgba(70, 205, 225, 0.7) 40%, rgba(41, 192, 212, 0.7) 100%);
    background-repeat: no-repeat;
    background-position: 87% 100%;
    background-size: auto calc(100vh + 20%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.text-wrapper{
    color: #003b68;
    letter-spacing: 2px;
    text-align: center;
    h2.big-text{
      font-size: 40px;
      font-weight: bold;
    }
    span.link {
      font-weight: 700;
      border-bottom: 1px solid;
      cursor: pointer;
    }
    div.return{
      color: #ffffff;
      font-size: 28px;
      margin-top: 2em;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &.big-text {
    div.text-wrapper {
      h2.big-text {
        font-size: 60px;
      }
      h3 {
        font-size: 35px;
        &:first-of-type {
          margin-bottom: 35px;
        }
      }
    }
  }
}

@media screen and ( max-height: 721px ){
  div.registration-success-wrapper{
    background-position: 28% 60%;
    background-size: 105% auto;
  }
}

@include media-max($layout-breakpoint-xs) {
  div.registration-success-wrapper {
    height: 100vh;
    //background: url('../../../assets/img/login/mobile-reg-success-bg.png'), linear-gradient(to bottom, rgba(117, 229, 246, 0.7) 0%, rgba(70, 205, 225, 0.7) 40%, rgba(41, 192, 212, 0.7) 100%);
    background-position: 75% 0%;
    background-size: cover;
    //background-size: 1188px 730px;
    div.text-wrapper {
      div.return {
        font-size: 20px;
      }
    }
    &.big-text {
      div.text-wrapper {
        h2.big-text {
          font-size: 30px;
        }
        h3 {
          font-size: 20px;

        }
      }
    }
  }
}