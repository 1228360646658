@import "../../assets/css/_variables.scss";
@import "../../assets/css/_colors.scss";
@import "../../assets/css/responsive";

div.profile-container.container{
  display: flex;
  justify-content: center;
  a {
    text-decoration: underline;
    color: var(--primary-color);
    font-size: 14px;
    display: block;
    margin-bottom: 20px;
  }
  div.under-text{
    div.error-message{
      color: red;
    }
  }
  .user-form{
    form.basic-details-form {
      div.checkbox{
        padding-top: 20px;
      }
      div.multi-select-container {
        margin-bottom: 20px;
        input, input::placeholder {
          color: inherit;
          font-family: 'Yed', 'arial';
          font-size: 16px;
        }
      }
      div.validated-input-container.auth-input {
        margin-left: 0;
        margin-bottom: 0;
        div[data-react-toolbox="input"]{
          input{
            color: inherit;
          }
        }
      }
    }
  }
  .company-details{
    border-right: solid 1px;
    margin-right: 25px;
    padding-right: 25px;
    form {
      div.validated-input-container.auth-input {
        div[data-react-toolbox="input"]{
          input{
            color: inherit;
          }
        }
      }
    }
  }
  div.multi-select-container {
    &.regions {
      ul {
        li {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            padding-right: calc(32px + 20px);
          }
        }
      }
    }
  }
}

div[data-react-toolbox="dialog"] {
  form.sms-verification-wrapper {
    padding: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div.validated-input-container.auth-input {
      width: 380px;
      max-width: 100%;
    }
    div.error-message{
      color: red;
    }
  }
}

@include media-max($layout-breakpoint-sm) {
  div.profile-container.container {
    div.outsider{
      width: 100%;
    }
    flex-direction: column;
    div.user-form{
      margin: 0;
      border: none;
    }
    div.company-details{
      margin: 0;
      border: none;
    }
    > div {
      padding: 2em;
      div.input-wrapper-container div[data-react-toolbox="input"].auth-input {
        max-width: 100%;
        width: 100%;
      }
      > form {
        div.validated-input-container.auth-input {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}