@import '../../assets/css/_colors.scss';

div.privacy-policy-wrapper {
  background-color: $bg-white;
  padding: 15px;
  a {
    text-decoration: underline;
    color: var(--primary-color);
  }
  div.title,
  strong.item-title {
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline;
  }
  ol {
    counter-reset: item;
    &.inner-inner-list {
      li {
        &:before {
          font-size: 17px;
        }
      }
    }
    &.inner-list {
      li {
        &:before {
          font-size: 18px;
        }
      }
    }
  }
  li {
    margin-bottom: 10px;
    display: block;
    p, div {
      display: inline;
    }
    &:before {
      content: " "  counters(item, ".");
      counter-increment: item;
      font-size: 20px;
      margin-left: 5px;
    }
  }

ol.top-list {
  //list-style-type: none;
  padding-right: 22px;
  font-size: 15px;
  li.list {
    p {
      strong.item-title {
        text-decoration: underline;
      }
    }
  }
}
}