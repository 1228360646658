.faq-page{
  //margin-top: 20px;
  .section-images{
    margin: 25px 0 40px 0;
    //margin: 25px 0;
    //margin: 50px 0;
    ul{
      display: flex;
      justify-content: space-between;
      li{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        * {
          max-width: 100%;
          box-sizing: border-box;
        }
        img{
          width: 225px;
          height: auto;
        }
        span.section-title{
          font-size: 13px;
          font-weight: bold;
          max-width: 200px;
        }
      }
    }
  }
  .spacer{
    height: 200px;
  }
  .return{
    cursor: pointer;
    span{
      text-decoration: underline;
    }
  }
  div.questions-container{
    min-height: 200px;
    div.question-container {
      padding: 10px;
      margin-bottom: 10px;
    div.question {
        margin-top: 10px;
        span {
          font-size: 17px;
          color: #F48220;
        }
      }
      div.answer{
        font-size:16px;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}