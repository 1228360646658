@import "../../assets/css/variables";
@import "../../assets/css/colors";
@import "../../assets/css/_responsive.scss";

div.auth-box {
  //padding: 30px;
  border: none;
  min-height: 600px;
  div.auth-box {
    min-height: 0;
  }
  &:not(.with-dots) {
    padding-top: 25px;
    h4 {
      color: $secondary-color;
    }
  }
  h4 {
    font-size: 32px;
    color: $primary-color;
    text-align: center;
    margin-bottom: 50px;
  }
  form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  div.under-fields {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 63px;
    margin-bottom: 10px;
    div.error-message {
      color: $error-message;
      align-self: center;
    }
    div.forgot-password,
    div.checkbox-wrapper-container .checkbox-label {
      font-size: 14.4px;
    }
    div.checkbox-wrapper-container {
      label {
        margin-bottom: 0;
      }
    }
    div.forgot-password {
      color: var(--primary-color);
      cursor: pointer;
      text-decoration: underline;
      margin-bottom: 25px;
    }
  }
  div.helpi-button-container {
    button {
      width: 200px;
    }
  }
  div.divider {
    //border-bottom:1px solid $white-three;
    background-color: $white-five;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 200px;
    height: 35px;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 22px;
    > div {
      padding: 0 13px;
      z-index: 1;
      background-color: $white-five;
      color: $text-color;
    }
    &::after {
      content: '';
      position: absolute;
      //background: $white-five;
      //font-size: 16px;
      //padding: 5px;
      width: 100%;
      height: 1px;
      background-color: $border-gray-green;
      z-index: 0;
      left: 0;
    }
  }
  div.auth-input {
    &:first-child {
      margin-bottom: 45px;
    }
  }
  form.sign-up-form {
    position: relative;
    div.conditions {
      position: absolute;
      bottom: -25px;
      left: 0;
      color: $text-color;
      text-align: center;
      a {
        color: var(--primary-color);
      }
    }
  }
  div.helpi-button-container {
    width: 200px;
    button.helpi-button.big {
      font-size: 17.6px;
      width: 200px;
      height: 48px;
      &.fb-button {
        font-size: 13.6px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 3.6;
      }
    }
  }
  div.buttons {
    width: 200px;
    div.helpi-button-container {
      width: 200px;
      button.helpi-button.big {
        font-size: 17.6px;
        width: 200px;
        height: 48px;
        &.fb-button{
          font-size: 13.3px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 3.6;
        }
      }
    }
  }
  div.conditions{
    font-size: 11px;
  }
}

@include media-max($layout-breakpoint-sm) {
  div.auth-box {
    form.sign-up-form {
      div.conditions {
        bottom: -45px;
      }
    }
  }
}

@include media-max($layout-breakpoint-xs) {
  div.auth-box {
    div.auth-input {
      &:first-child {
        margin-bottom: 25px;
      }
    }
    div.under-fields {
      height: 40px;
      div.forgot-password {
        margin-bottom: 0;
      }
    }
    div.divider {
      margin-top: 0;
      margin-bottom: 0;
      justify-content: flex-start;
      font-size: 18px;
      div {
        padding: 0;
        &::after {
          content: ':';
        }
      }
      &::after {
        height: 0;
      }
    }
  }
}
