@import '../../assets/css/_colors.scss';

div.picture-list-container {
  div.card,
  div.link {
    div.heading {
      font-size: 24px;
    }
  }
  div.heading-text-container {
    div.heading {
      font-size: 25px;
      padding: 0 80px;
    }
    &::before {
      background-color: $picture-list-heading-blue;
    }
  }
  div.card {
    ul.card-container {
      //width: 90%;
      margin: 40px auto;
      display: flex;
      flex-wrap: wrap;
      li {
        min-height: 200px;
        flex-basis: 33%;
        font-size: 12px;
      }
    }
  }
  div.link {
    ul.link-container {
      width: 100%;
      padding-top: 40px;
      padding-bottom: 40px;
      flex-flow: row wrap;
      display: flex;
      margin: auto;
      height: 100%;
      li.logo {
        flex: auto;
        width: 50px;
        text-align: center;
        display: flex;
        //flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 60%;
          height: auto;
          margin: auto;
          max-height: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 959px) and (min-width: 600px) {
  div.cases {
    ul.cases-grid {
      li {
        max-width: 280px;
      }
    }
    div.success {
      ul.success-container {
        li {
          flex-basis: 50%;
        }
      }
    }
    div.partners {
      ul.logo-container {
        li.logo {
          flex-basis: 20%;
        }
      }
    }
  }
}

@media (max-width: 599px) {
  div.picture-list-container {
    div.card {
      ul.card-container {
        li {
          flex-basis: 100%;
        }
      }
    }
    div.link {
      ul.link-container {
        li.logo {
          flex-basis: 20%;
        }
      }
    }
  }
}