.fb-share-icon {
  background-image: url('assets/img/icons/fb-share-icon2.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  //width: 30px;
  //height: 32px;
  width: 27px;
  height: 27px;
}

.email-share-icon {
  background-image: url('assets/img/icons/email-share-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  width: 30px;
  height: 32px;
}

.whatsapp-share-icon {
  background-image: url('assets/img/icons/whatsapp-share-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  //width: 30px;
  //height: 32px;
  width: 27px;
  height: 27px;
}