@import "../../assets/css/_colors.scss";

div.newsletter-modal-wrapper {
  width: 630px;
  section[role=body] {
    div.modal-header {
      text-align: center;
      justify-content: center;
      padding: 2em 1.1em;
      h2 {
        font-size: 26px;
      }
    }
    div.child-modal {
      div.child-modal-inner {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
        align-items: center;
        div.big-text,
        div.small-text {
          text-align: center;
        }
        div.big-text {
          font-size: 20px;
          font-weight: 500;
          margin-top: 10px;
        }
        div.small-text {
          font-size: 14px;
          margin-bottom: 30px;
        }
        div.outsider {
          height: 59px;
          &:nth-child(3) {
            margin-bottom: 10px;
          }
          div.multi-select-container.regions {
            ul.list {
              li.list-item {
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                  padding-right: 30px;
                }

              }
            }
          }
        }
        div.outsider,
        div.inputs {
          width: 400px;
          font-size: 12px;
          label.description-label,
          input.multi-select-input {
            font-size: 12px;
            color: #444;
          }
          label.description-label {
            margin-right: 10px;
          }
          input {
            border: 1px solid #000;
          }
          div.validated-input-input > input,
          input.multi-select-input {
            padding-right: 10px;
          }
        }
        div.inputs {
          margin-bottom: 10px;
        }
        div.helpi-button-container {
          width: 400px;
          button {
            width: 100%;
          }
        }
        div.close-modal {
          color: $faded-text;
          border-bottom: 1px solid $faded-text;
          line-height: 1;
          margin-top: 12px;
          margin-bottom: 10px;
          font-size: 12px;
          cursor: pointer;
        }
        div.errors {
          height: 16px;
          margin-bottom: 10px;
          color: $error-message;
        }
      }
      div.newsletter-success {
        padding: 2em;
        text-align: center;
        div.big-text {
          font-size: 26px;
          font-weight: 500;
          color: #000;
        }
        div.small-text {
          font-size: 14px;
          margin-top: 15px;
          margin-bottom: 25px;
          line-height: 1.44;
          color: var(--text-color);
        }
      }
      div.unsubscribe-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        > div:not(.helpi-button-container) {
          padding: 15px;
          font-size: 14px;
        }
        div.helpi-button-container {
          margin-bottom: 30px;
        }
      }
    }
  }
}