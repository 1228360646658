@import "../../assets/css/_colors.scss";

div.heading-text-container {
  position: relative;
  display: flex;
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $heading-line-blue;
    top: 50%;
    left: 0;
    z-index: 0;
  }
  div.heading {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 30px;
    background-color: $bg-white;
    font-size: 20px;
    //color: $label-color;
    z-index: 0;
  }
}