@import '../../assets/css/_colors.scss';

div.email-validation-modal {
  div.child-modal {
    padding: 70px 60px;
    div {
      text-align: center;
    }
    span {
      cursor: pointer;
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
    }
  }
}