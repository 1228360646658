@import '../../assets/css/_colors.scss';

div.select-container {
  -webkit-tap-highlight-color: transparent;
  label {
    right: 0;
  }
  > div {
    > div {
      &::after {
        left: 8px;
        right: auto;
        top: calc(50% - (6px / 2));
      }
    }
  }
  ul {
    display: block;
    top: -46px;
    li {
      &.selected-item {
        color: $bright-blue;
      }
    }
  }
  div.included-label {
    ul {
      li:first-child {
        pointer-events: none;
        color: rgba(117,117,117,0.87);
        padding: 10px;
      }
    }
  }
}

@media (max-width: 959px) {
  div.select-container {
    > div{

    > div::after{
      border-left-width: 4.48571px;
      border-right-width: 4.48571px;
      border-top-width: 4.48571px;
    }
    }
  }
}