@import "../../assets/css/_variables.scss";
@import "../../assets/css/_colors.scss";
@import "../../assets/css/responsive";

div.cases {
  max-width: 100%;
  font-size: $med-small;
  div.header-container-cases {
    div.donors-mobile{
      display: none;
    }
    //min-height: 400px;
    height: calc(99vw / 4.8);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    //margin-bottom: 40px;
    //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    // background-image: url('./../../assets/img/default-bg.png'); 
    background-image: var(--homepage-banner-img);
    div#header-inner-wrapper {
      color: #fff;
      margin-top: 70px;
      text-align: center;
    }
    div.white-label-container {
      position: absolute;
      right: 0;
      div.imgWrapper {
        max-width: 200px;
        max-height: 200px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      div.text {
        color: var(--primary-color);
      }
    }
    div.filters.filters-container {
      //width: 877px;
      ul.filter-list {
        li,
        li span,
        li label {
          font-size: 12px;
        }
        li {
          //width: 194px;
          &:last-child {
            width: 190px;
          }
          div.multi-select-container {
            div.multi-select-list-wrapper {
              width: 231px;
              color: rgba(33,33,33,0.87);
              li.label-display {
                font-size: 14px;
              }
              li:nth-child(2) {
                span {
                  font-weight: 600;
                }
              }
            }
          }
          div.select-container {
            &:focus {
              ul {
                outline: none;
                border: none;
                box-shadow: none;
              }
              span {
                display: none;
              }
            }
            ul {
              top: - 32px;
              width: 254px;
              li {
                width: 100%;
                box-sizing: border-box;
                height: 22px;
                display: flex;
                align-items: center;
                padding: 0 32px;
              }
            }
            div.regions {
              ul {
                li {
                  &:nth-child(4),
                  &:nth-child(5),
                  &:nth-child(6) {
                    padding-right: calc(32px + 20px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  div.powered {
    direction: ltr;
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    color: $primary-color;
    font-family: 'Roboto';
    margin-top: -15px;
    margin-bottom: 15px;

    span {
      margin-right: 5px;
      white-space: nowrap;
    }

    img {
      height: 23px;
    }
  }

  aside.scroll-banner {
    background-color: var(--secondary-color);
    padding: 5px;
    display: flex;

    div.filters.filters-container {
      margin: 12px auto;

      button {
        border: 1px solid #fff;
      }
    }
  }

  div.hours-successes-wrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: calc((20 / 1920) * 100vw) calc((100vw - 1100px) / 2) calc((30 / 1920) * 100vw);
    justify-content: center;
    background-color: var(--primary-color);
    align-self: flex-end;
    margin-bottom: 40px;

    &.no-success {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 15px;

      h2,
      h3 {
        color: var(--success-strip-text-color);
      }

      h2 {
        font-size: 24px;
        font-weight: 600;
      }

      h3 {
        font-size: 16px;
      }
    }

    div {
      color: var(--success-strip-text-color);
      font-weight: 700;
      display: flex;
      flex-direction: column;
      padding: 0 70px;
      align-items: center;
      justify-content: center;
      border-left: 1.1px solid var(--success-strip-text-color);

      &:last-child {
        border-left: none;
      }

      img {
        width: 4.5vw;
      }

      span {
        //font-size: 22px;
        font-size: calc(100vw * (22 / 1920));
        white-space: nowrap;

        &.number {
          font-size: calc(100vw * (30 / 1920));
          //font-size: 30px;
        }
      }
    }

    div.hours {
      span {
        display: block;
        text-align: center;
      }
    }

    div.success {
      flex-direction: row;
      align-items: center;
      padding-left: 0;

      p {
          margin: 0 20px 0 0;
          font-size: calc(100vw * (16 / 1920));
          max-width: 22vw;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        a {
          //font-size:
          letter-spacing: 0.4px;
          
        }
      }
    }
  }
}

div.disabled-case-modal {
  section[role="body"] {
    div.modal-disabled-case-content {
      //padding: 3em;
      width: 655px;
      height: 215px;
      display: flex;
      flex-direction: column;
      padding: 2em 2em;
      max-width: 90vw;
      div.contact-text{
        text-align: center;
        //flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //margin-bottom: 3em;
        color: var(--text-color);
        h2,
        h4,
        p {
          font-size: 16px;
        }
        h2{
          margin-bottom: 0.5em;
        }
        h4{
          margin: 10px 0;
        }
        p {
          margin-top: 10px;
        }
        a {
          color: var(--primary-color);
        }
      }
    }
    div.actions {
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      div.helpi-button-container{
        button.helpi-button{
          padding: 0.5em 1.6em;
          font-size: 18px;
          font-weight: 500;
          &.transparent{
            background: transparent;
            color: var(--secondary-color);
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1025px) and (orientation: landscape) {
  div.cases {
    div.picture-list-container {
      div.link {
        ul.partners-container {
          li.logo {
            flex-basis: 20%;
          }
        }
      }
    }
  }
}

@include media-max($layout-breakpoint-sm){
  div.cases {
    div.header-container-cases {
      div.donors-mobile{
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        margin-top: auto;
      }
      height: 441px;
      background-size: 100% 100%;
      background-position: 50%;
      div#header-inner-wrapper {
        margin-top: 0;
      }
      div.filters.filters-container {
        padding-top: 20px;
        ul.filter-list {
          li {
            &:last-child {
              width: 194px;
            }
          }
        }
      }
    }
    div.picture-list-container {
      div.link {
        ul.partners-container {
          li.logo {
            flex-basis: 20%;
          }
        }
      }
    }

    div.hours-successes-wrapper {
      padding: 8px 0;

      img {
        //width: 4.5vw;
      }
      
      &.no-success {
        h2,
        h3 {
          text-align: center;
        }

        h2 {
          font-size: 21px;
        }

        h3 {
          font-size: 13px;
        }
      }

      div {
        padding-left: 0;
        padding-right: 0;
        flex-basis: 50%;
        span {
          font-size: 12px;

          &.number {
            font-size: 20px;
          }
        }

        img {
          height: 20px;
          width: auto;
        }

        &:first-child {
          padding-right: 0;
        }

        &:last-child {
          padding-left: 0;
        }

        &.success {
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding-left: 50px;
          padding-right: 50px;

          p {
            margin-right: 0;
            font-size: 12px;
            //white-space: nowrap;
          }
        }
      }
    }
    
    aside.scroll-banner {
      display: none;
    }
  }
  div.disabled-case-modal {
    section[role="body"] {
      div.modal-disabled-case-content {
        padding-bottom: 1em;
        height: auto;
        min-height: 200px;
        div.contact-text{
          h2,
          h4,
          p {
            font-size: 12px;
          }
          h2{
            margin-bottom: 0.3em;
          }
          h4{
            margin: 5px 0;
          }
          p {
            line-height: 1.2;
            margin-top: 5px;
            a {
              color: var(--primary-color);
            }
          }
        }
      }
      div.actions {
        display: flex;
        justify-content: space-between;
        margin-top: .8em;
        div.helpi-button-container{
          button.helpi-button{
            padding: 0.5em 1.6em;
            font-size: 13px;
            font-weight: 500;
            &.transparent{
              background: transparent;
              color: var(--secondary-color);
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $layout-breakpoint-xs) and (min-width: 300px) {
  div.cases {
    div.header-container-cases {

      background-size: cover;
      background-image: var(--homepage-banner-img-mobile);
    }

    div.hours-successes-wrapper {
      div.success {
        padding-left: 0;
        padding-right: 0;

        p {
          white-space: nowrap;
        }
      }
    }

    div.powered {
      margin-top: -25px;
    }
  }
}

//@media (max-width: 959px) and (min-width: 600px) {
//  div.cases {
//    div.header-container-cases {
//      height: 480px;
//      div#header-inner-wrapper {
//        margin-top: 0;
//      }
//    }
//  }
//}
//
//@media (max-width: 599px) {
//  div.cases {
//  }
//}
