div.contact-container {
  padding-top: 0;
  .mapContainer {
    height: 250px;
    position: relative;
    .map {
      max-width: 100%;
      height: 250px;
      position: static;
    }
  }

  .view-container {
    padding-top: 0;
  }
}