@import "../../../node_modules/font-awesome/scss/font-awesome.scss";
@import "./colors";
@import "./mixins";

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --text-color: #{$text-color};
  --heading-text: #ffffff;
  --header-logo: url('assets/img/new-logo-2022.png');
  --heading-bg-color: #{$header-bg};
  --menu-link-color: #666;
  --success-strip-text-color: #ffffff;
  --menu-link-hover-color: #000;

  // --homepage-banner-img: url('assets/img/emergency-web.png');
  --homepage-banner-img: url('assets/img/new_cover.jpg');
  --homepage-banner-img-tablet: url('assets/img/new_cover.jpg');
  --homepage-banner-img-mobile: url('assets/img/new_cover.jpg');
}

html, body {
  height: auto;
}

body {
  min-width: 320px;
  font-family: 'Yed', 'arial';
  font-weight: normal;
  color: var(--text-color);
  font-size: 12px;
}
button, html, input, select, textarea {
  font-family: 'Yed', 'arial';
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: normal;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}
.md-button:not([disabled]):hover {
  background-color: var(--primary-color);
}

.link-look {
  color: #1ABCC5;
  text-decoration: underline !important;
  cursor: pointer;
}

.clickable-content {
  cursor: pointer;
}

.float {
  float: left;
}

.float-right {
  float: right;
}

.gi-button {
  background-color: var(--primary-color);
  color: #ffffff;
  font-family: "Yed";
  font-weight: lighter;
  border-radius: 2px;
  border: 0px;

  &.btn-primary {
    height: 30px;
    font-size: 17px;
    min-width: 130px;
  }

  &.btn-secondary {
    height: 24px;
    font-size: 14px;
    min-width: 130px;
  }
  &[disabled="disabled"] {
    opacity: 0.5;
  }
  button:not([disabled]):hover & {
    background-color: var(--primary-color);
  }

}

.view-container {
  min-height: 500px;
  max-width: $layout-breakpoint-md;
  margin: auto;
  padding: 20px 0;
  padding-top: 100px;
  .home-route & {
    padding-top: 20px;
  }
}
partners {
  position: relative;
}
@include media-max($layout-breakpoint-sm) {
  .view-container {
    padding-top: 60px;
  }
}

@include media-min($layout-breakpoint-sm) {
  .view-container {
    max-width: $layout-breakpoint-sm;
  }
}

@include media($layout-breakpoint-xs, $layout-breakpoint-sm) {
  .view-container {
    max-width: $layout-breakpoint-xs;
  }
}


.main-panel {
  background-color: #E6E6E6;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  .panel-section:last-child {
    border-bottom: 2px solid white;
  }
}

.panel-section {
  border-top: 2px solid white;
  min-height: 70px;
}

.panel-section-inner {
  padding: 8px;
}
//.card-container {
//  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
//}

.orange-bubble {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  background-color: rgba(245, 130, 32, 0.8);

  &.small {
    height: 28px;
    width: 28px;
  }
}

.blue-bubble {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background-color: var(--primary-color);
  background-color: rgba(34, 165, 185, 0.8);

  &.small {
    height: 28px;
    width: 28px;
  }
}

.cases-search-view .blue-bubble {
  position: absolute;
  top: -19px;
  left: 50%;
  margin-left: -19px;
}

.section-seperator {
  width: 100%;
  border-bottom: 1px solid #A9DCE3;
  height: 20px;
  text-align: center;

  .seperator-text {
    padding: 0px 30px;
    font-size: 30px;
    background-color: rgb(250,250,250);
  }
}

.backdrop {
  background-color: #212121;
  opacity: 0.48;
  z-index: 1;
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
}

.md-dialog-container {
  /* This handles the case where the page has long scroll, which caused the dialog to appear on the bottom
  of the screen.
  */
  /*
    I think there should be a better way to handle this issue. I've added overflow:visible temporarily as it doesn't
    ruin any existing dialog boxes and allows the new popup to show. This should be looked at again -JM
   */
  max-height: 800px;
  overflow: visible;
}

md-dialog .md-dialog-content {
  padding: 15px;
  a {
    color: var(--primary-color);
  }

  md-input-container {
    &.md-input-focused, &.md-input-has-value {
      label, md-select{
        font-size: 16px;
      }
      label {
        font-size: 16px;
        color: $label-color;
        width: auto;
      }
    }
  }

}

select-box {
  > md-select {
    padding: 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

md-input-container {
  margin-top: 18px;
  margin-bottom: 0px;
  label, .md-placeholder, label:not(.md-no-float):not(.md-container-ignore){
    color: $label-color;
    width: auto;
  }
}

/**
This is the 'How do You Want To Volunteer?' dropdown in the header
 */
#select_container_13{
  md-select-menu, md-select-menu md-content{
    max-height: 266px;
  }
}

/**
Regions filter box
 */
#select_container_24{
  md-select-menu, md-select-menu md-content{
    max-height: 290px;
  }
}

.history-entry {
  margin-bottom: 10px;
}

.history-entry button {
  min-width: 25px;
  min-height: 25px;
  line-height: 10px;
  position: absolute;
  margin-top: -3px;
}

.user-logs{
  margin-top: 20px;
  overflow: auto;
  max-height: 200px;
}

.Toastify__toast-container--bottom-left {
  bottom: 0.3em;
  left: 0.3em;
  width: auto;
  .Toastify__toast {
    min-height: 0;
    padding: 1em 2.1em;
    min-width: 288px;
    margin-top: 10px;
    margin-bottom: 0;
    &.Toastify__toast--error {
      background-color: #D50808;
    }
    &.Toastify__toast--success {
      background-color: #4CAF50;

    }
    .Toastify__close-button {
      display: none;
    }

    .Toastify__toast-body {
      font-size: 14px;
      font-family: 'Yed', 'arial';
      font-weight: bold;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
}


.notifications-container {
  position: fixed;
  z-index: -1;
  width: 100%;
  bottom: 0px;
  .message {
    &.success {
      .md-toast-content {
        background-color: #4CAF50;
      }
    }
    &.error {
      .md-toast-content {
        background-color: #D50808;
      }
    }
  }
  &.displayContent {
    z-index: 9999;

  }
}

.success-container {
  margin-top: 30px;
  div {
    text-align: -webkit-center;
  }
}
p.contactText{
  font-size: 1.2em;
  max-width: 500px;
  margin: 3em auto;
}

.md-dialog-content {
  max-width: 400px;
}

.send-success {
  margin-top: 40px;
  font-size: 1.5em;
  color: #00a8c6;
  text-align: center;
}

/**
there was an issue with the google maps api v3.32. Rather than specify an earlier version, this is a simple workaround, as its
an entirely css related issue
 */
input[g-places-autocomplete] {
  display: initial !important;
}
.contact-us-form {
  margin: 0 auto;
  input, textarea{
    display: block;
    margin-bottom: 15px;
    height: 27px;
    width: 300px;
    line-height: 27px;
    padding-right: 10px;
    font-family: 'Yed', 'arial';
  }
  input[disabled] {
    opacity: 0.5;
  }
  textarea {
    height: 100px;
  }
  .submit-contact {
    background-color: rgba(88, 145, 161, 1);
    color: white;border: none;
    height: 35px;
    width: 96px;
    font-family: 'Yed', 'arial';
    border-radius: 7px;
    outline:none;
    margin: 0 auto;
  }
}

.ng-timepicker {
  margin-right: 90px;
  margin-top: -37px;
  direction: ltr;
}

gi-tabs .gi-tabs-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width:400px;
}

gi-tabs .gi-tab{
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;
  padding: 5px;
}

gi-tabs .gi-tab.selected {

  color: #FFF !important;
}

.gi-datetime-container {
  display: flex;
}

.gi-filter-search-btn {
  background: $gi-filter-search-btn-color;
  color: #FFF;
}

.gi-filter-buttons {
  text-align:left;
}

.gi-filter-clear-btn {
  border: 1px solid #C1C1C1;
}

input, select, textarea {
  font-size: 16px;
}

.upload-button {
  min-width: 50px !important;
  width: 50px !important;
  margin: 0px !important;
  md-icon {
    color: #FFF !important;
  }
}

.upload-container {
  margin: 5px !important;
}

.decorated-title {
  justify-content: center;
  align-items: center;
  width:100%;
  display:flex;

  span {
    font-size: 2em;
    margin: 0px 80px 0px 80px;
    white-space: nowrap;
  }
}

#partners-box {
  width:100%;


  .logo-container {
    padding-top: 40px;
    padding-bottom: 40px;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    -ms-flex-direction: column;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    display: -webkit-box;
    display: flex;
    margin:auto;
    height:100%;
  }
  .logo {
    -ms-flex: auto;
    flex: auto;
    width: 50px;
    text-align:center;
    display:flex;
    flex-direction: column;

    img {
      //width: 60%;
      height: auto;
      margin: auto;
      cursor: pointer;
    }
  }
}

@include media-max($layout-breakpoint-sm) {
  #partners-box {
    .logo {
      flex-basis: 20%;
      margin-bottom: 10px;
    }
  }

  .signup-modal {
    min-height: 390px;
  }

  #partners-title {

    span {
      margin: 0px 30px 0px 30px !important;
    }
  }
}

.gi-action-icon {
  margin: auto;
}

.gi-action-icon.fa {
  font-size: 15px;
}

.gi-action-icon:hover {
  color: $gi-icon-hover-color;
  cursor: pointer;
}

//terms and conditions
div.WordSection1{
  p, p>span, span{
    font-family: Yed, Arial, sans-serif !important;
  }
  u > span{
    font-weight: bold;
  }
}
