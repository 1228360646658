@import "../../assets/css/_colors.scss";
@import "../../assets/css/_variables.scss";
@import "../../assets/css/_responsive.scss";

div.page-wrapper {
  background-color: $bg-white;
  padding-top: calc(#{$header-height} + 40px);
  padding-bottom: 40px;
  //header height not included because it is a fixed component in 95% of pages
  min-height: calc(100vh - #{$footer-height-desktop});
  > div {
    height: 100%;
  }
  &.home{
    padding-top: 0;
    padding-bottom: 0;
  }
  &.login-route {
    height: 100vh;
    padding: 0;
  }
}

@include media-max($layout-breakpoint-sm) {
  div.page-wrapper {
    padding-top: calc(#{$header-mobile-height} + 40px);
  }
}