@import '../../assets/css/responsive';

.success-page {
  .cases {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 11px;
    div.case-card {
      box-shadow: none;
      display: flex;
      justify-content: center;
      .case {
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
        margin-bottom: 40px;
        width: 300px;
        .case-details {
          padding: 10px;
          .case-title {
            height: auto;
            margin-bottom: 0;
            text-align: center;
          }
        }
      }
    }
  }
}

@include media($layout-breakpoint-xs, $layout-breakpoint-sm){
  .success-page {
    .cases {
      div.case-card {
        .case {
          width: 280px;
        }
      }
    }
  }
}
