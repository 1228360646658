@import '../../assets/css/_colors.scss';
@import '../../assets/css/_mixins.scss';

$company-case-text-wrap-height: 40px;


div.case-card {
  //width: 300px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  &.authorized {
    position: relative;
    div.authorized-case {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      top: calc(225px - 50px);
    }
  }
  div.case {
    > div.authorized-case {
      background-color: var(--primary-color);
      color: #fff;
      text-align: center;
      padding: 9px 3px;
      font-size: 12px;
      span {
        font-weight: 500;
        display: block;
      }
      span.disabled-link {
        opacity: 1;
        text-decoration: underline;
      }
    }
    > div:not(.authorized-case) {
      position: relative;

      img.case-img {
        object-fit: cover;
      }

      img.case-img,
      div.company-case-frame{
        width: 100%;
        height: 225px;
      }
      div.company-case-frame {
        position: absolute;
        border: 4px solid var(--primary-color);
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;
        pointer-events: none;
        div.group-icon-wrapper {
          //position: relative;
          //top: -10px;
          img {
            position: relative;
            top: 5px;
          }
          &:before {
            content: '';
            position: absolute;
            bottom: $company-case-text-wrap-height;
            left: calc(50% - (80px / 2));
            height: 46px;
            width: 80px;
            border-radius: 80px 80px 0 0;
            z-index: -1;

            @include primary-bg-opacity;
          }
        }
        div.text-wrapper {
          height: $company-case-text-wrap-height;
          //background-color: rgba(var(--primary-color-rgb), 0.8);
          font-size: 18px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          position: relative;

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;

            @include primary-bg-opacity;
          }
        }
      }
    }
    div.case-details {
      padding: 10px;
      text-align: right;
      div.case-title {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 28px;
        overflow: hidden;
        line-height: 14px;
        font-size: 12px;
      }
      div.case-actions {
        display: flex;
        justify-content: center;
      }
    }
  }
}