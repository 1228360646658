@import "../../assets/css/_colors.scss";

div.checkbox-wrapper-container {
  display: flex;
  align-items: center;
  //margin-bottom: 15px;
  label.helpi-checkbox {
    //margin-bottom: 0;
    font-family: 'Yed';
    > div {
      margin-left: 10px;
      min-width: 18px;
    }
    &.checked {
      color: var(--primary-color);
      > div {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        &::after {
          border-color: #000;
        }
      }
    }
    &.colored-box{
      span.checkbox-label {
        color: $label-color;
      }
      &.primary {
        > div {
          background-color: rgba(213, 237, 240, 1);
          border-color: rgba(213, 237, 240, 1);
        }
        &.checked {
          > div {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            &::after {
              border-color: #fff;
            }
          }
        }
      }
      &.secondary {
        > div {
          background-color: rgba(255, 232, 211, 1);
          border-color: rgba(255, 232, 211, 1);
        }
        &.checked {
          > div {
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            &::after {
              border-color: #fff;
            }
          }
        }
      }

    }
    //> div::after {
    //}
  }
}