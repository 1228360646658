@import "../../assets/css/_variables.scss";

div.success-card-container {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  min-height: $story-height;
  //flex-basis: 20%;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  font-size: 14px;

  .blue-bubble {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-color: #1ABCC5;
    background-color: rgba(34, 165, 185, 0.8);
    align-self: flex-end;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 15px;
  }

  div.story-circle {
    border-radius: 50%;
    width: $circle-dim;
    height: $circle-dim;
    background-size: cover;
    background-position: 50%;
    display: flex;
    margin: auto;
    flex: 0 0 auto;
  }

  div.story-title {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    min-height: 80px;
  }
}
