@import '../../../assets/css/_colors.scss';
@import '../../../assets/css/_responsive.scss';

form.basic-details-form {
  flex-flow: row wrap;
  max-width: 855px;
  justify-content: center;
  div.validated-input-container.auth-input {
    margin-bottom: 24px;
  }
  div.validated-input-container.auth-input,
  div.outsider {
    width: 400px;
    &:first-of-type,
    &:nth-child(3) {
      margin-left: 55px;
    }
  }

  div.multi-select-container {
    margin-bottom: 22px;
    &.regions {
      ul {
        li {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            padding-right: calc(32px + 20px);
          }
        }
      }
    }
    input {
      font-size: 22px;
      color: $label-color;
      background-color: transparent;
      border-bottom: 1px solid $border-gray-green;
      &::placeholder {
        color: #bfbfbf;
        font-size: 22px;
      }
    }
    div.multi-select-list-wrapper {
      border: 1px solid $border-gray-green;
      li {
        label {
          > div {
            border-color: transparent;
          }
        }
      }
    }
  }

  div.checkbox {
    flex-basis: 100%;
  }

}

@include media-max($layout-breakpoint-xs) {
  form.basic-details-form {
    div.multi-select-container {
      input {
        font-size: 20px;
      }
    }
  }
}