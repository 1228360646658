@import '../../../assets/css/_colors.scss';

div.login-profile-wrapper {
  padding-left: 30px;
  min-width: 130px;
  height: 100%;
  font-size: 13px;
  font-weight: 800;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 0;
  a.login-button,
  div.user-dropdown{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5em;
    color: var(--text-color);
    width: 100%;
    //height: 100%;
  }

  a:hover {
    color: var(--menu-link-hover-color);
  }

  a.login-button {
    background-color: rgba(0,0,0,0);
    position: relative;
    top: 11px;
    &:before {
      content: url('./../../../assets/img/user.png');
      position: absolute;
      bottom: 6px;
      right: -14px;
    }
  }
  div.user-dropdown {
    position: relative;
    div.name {
      //padding-bottom: 10px;
      position: relative;
      //display: flex;
      //align-items: baseline;
      &:after{
        content: '';
        border-left: 5.48571px solid transparent;
        border-right: 5.48571px solid transparent;
        border-top: 5.48571px solid var(--menu-link-color);
        bottom: calc(50% - 5.48px / 2);
        height: 0;
        right: 100%;
        position: absolute;
        pointer-events: none;
        width: 0;
        margin-right: 9px;
      }
    }
    ul.list-wrapper {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: $header-bg;
      display: none;
      &.open {
        display: block;
      }
      li {
        text-align: center;
        border-bottom: 1px solid var(--menu-link-color);
        &:last-child {
          border-bottom: none;
        }
        > * {
          display: block;
          padding: 10px 0;
          cursor: pointer;
        }
      }
    }
  }
}