@import '../../assets/css/_colors.scss';
@import '../../assets/css/responsive';

div.request-type-menu{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &.not-clickable {
    div.case-type-square {
      cursor: default;
    }
  }
  div.case-type-square {
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    outline: none;
    width: 20%;
    background-color: #666;
    color: $light-sky-blue;
    margin: 8px;
    display: flex;
    flex-direction: column;
    &.chosen {
      border: 2px solid #e3e3e3;
    }
    img{
      max-width: 100%;
      //ie11 bug
      flex-shrink: 0;
    }
    label{
      //flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: $layout-breakpoint-xs) {
  div.request-type-menu {
    div.case-type-square {
      font-size: 12px;
      flex-direction: column-reverse;
      label{
        justify-content: center;
        align-items: center;
        padding: 0 2px;
      }
    }
  }
}