div.date-time-case-picker-container {
  display: flex;
  align-items: flex-end;
  div.date-container{
    label.dateLabel{
      display: flex;
      align-items: center;
      position: relative;
      i{
        margin-left: 0.9em;
      }
    }
    div.rdt{
      position: static;
    }
    div.rdtPicker{
      right: 0;
    }
    input {
      box-sizing: border-box;
      border: none;
      box-shadow: none;
      outline: none;
      background: transparent;
      min-width: 120px;
      max-width: 328px;
      position: relative;
      padding-bottom: 5px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: rgba(0,0,0,0.12);
      display: inline-block;
      width: auto;
      &:focus {
        border-color: #1ABCC5;
      }
    }
    &.notValid{
      input {
        border-color: red;
      }
    }

  }
  div.time-container{
    input{
      width: 55px;
      padding: 5px;
      border: 1px solid #d4d4d4;
      display: block;
      color: var(--text-color);

    }
    .rdtPicker {
      top: calc(100% - 71px);
      right: calc(100% + 35px);
      width: 150px;
    }
  }
}