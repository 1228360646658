div.form-container{
  display: flex;
  direction: rtl;
  margin-top: 10px;
  font-size: 15px;
  padding: 15px;
  form.contact-us-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    .submit-contact{
      background-color: #5891a1;
      color: white;
      border: none;
      height: 35px;
      width: 96px;
      font-family: 'Yed', 'arial';
      border-radius: 7px;
      outline: none;
      margin: 0 auto;
    }
  }
}