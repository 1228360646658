@import '../../assets/css/_colors.scss';

div.tool-tip-wrapper {
  height: 12px;
  width: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
  background-color: $border-gray-green;
  cursor: pointer;
  margin-right: 10px;
  .tool-tip-wrapper-inner {
    .tool-tip-inner {
      font-size: 15px;
      background-color: $border-gray-green;
    }
  }
}

span[data-react-toolbox="tooltip"]{
  font-size: 13px;
  .tool-tip-inner{
    padding: 0.4em;
  }
}