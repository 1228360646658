.tasksHistory{

  &__list{

    &__item{

      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid black;
      padding: 0.5rem 1rem;
      transition: 0.3s all;
      &:nth-child(even){

        background:#c1c1c1;
      }

      &:hover{
        background: var(--heading-bg-color);
        color: white;
        & > .tasksHistory__list__item-link > .helpi-button-container > .helpi-button{
          background: black;
        }
      }

      &-title{

        font-size: 1rem;
      }
    }
  }
}