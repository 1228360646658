@import "../../assets/css/_responsive";
@import "../../assets/css/_variables.scss";
@import "../../assets/css/_colors.scss";
.about-us-page {
  padding-top: 35px;
  margin-bottom: -1.1em;
  h3 span{
    //width: 150px;
    font-size: 22px;
    //color: #22a4b8;
    padding-right: 4px;
    //border-bottom: 1px solid #f79948;
  }
  a {
    color: var(--primary-color);
  }
  i.fa{
    font-size: 20px;
  }
  div.accordion-card-container{
    &:last-of-type{
      h3 {
        border-bottom: 1px dotted #d4d4d4;
      }
    }
  }
  h3{
    //margin-top: 20px;
    padding: 20px 0 20px 5px;
    cursor: pointer;
    border-top: 1px dotted #d4d4d4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //&:last-of-type{
    //  border-bottom: 1px dotted #d4d4d4;
    //}
    &:first-of-type{
      margin-top: initial;
    }
  }
  font-size: 16px;
  .hero{
    font-size: 2em;
  }
  .section-seperator {
    height: 16px;
    margin-bottom: 30px;
    &.main-title {
      border-bottom: 2px solid #A9DCE3;
      margin-top: 0px;
      font-size: 22px;
      line-height: 28px;
    }
    &:not(.main-title){
      margin-top: 30px;
    }
    .seperator-text{
      font-size: 26px;
    }
  }
  div.beginning, div.vision{
    margin: 0 0 60px;
  }
  div.team{
    margin: 0 0 70px;
  }
  .founders-section {
    display: flex;
    //first justify content for ie11 compatibility
    justify-content: space-around;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .member-item {
      margin-top: 20px;
      background-color: rgba(45, 169, 188, 0.14);
      min-height: 128px;
      width: 40%;
      box-sizing: border-box;
      &.textutal {
        margin: 10px;
      }
      padding: 20px;
      .member-text {
        text-align: center;
      }
      .member-container {
        .img-container {
          display: flex;
          justify-content: center;
          height: 190px;
          width: 190px;
          border-radius: 50%;
          margin-left: auto;
          margin-right: auto;
          overflow: hidden;
          .member-img {
            width: 190px;
            min-height: 190px;
            max-height: 190px;
            align-self: flex-start;
            background-position: 50%;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        .member-text {
          font-weight: bold;
          padding-top: 10px;
          //padding-right:;
          display: block;
          margin-bottom: 0;
        }
        .description {
          display: block;
          margin: 1em 0 0;
          min-height: 110px;
          padding-right: 15px;
        }
        div.founder-data {

        }
        a {
          color: var(--primary-color);
          text-align: center;
          display: block;
        }
        .bubble {
          /* PLEASE NOTE - container must be position:relative */
          margin: auto;
          position: absolute;
          bottom: 16px;
          right: 0px;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background-color: rgba(55, 176, 188, 0.6);
        }
      }

    }
  }
  div.heading-text-container{
    margin: 30px 0 10px 0;
    div.heading {
      font-size: 26px;
    }
  }

  h4{
    font-weight:bold;
    margin-top: 4em;
  }
  .credits-line {
    margin-top: 30px;
    background-color: #565656;
    color: #ffffff;
    padding: 15px;
    text-align: center;
  }

  ul.goals{
    padding-right: 40px;
    list-style-type: disc;
  }
  ul.managers{
    li{
     //
    }
  }
  ul.volunteers{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    li{
      flex-basis: 33%;
      &:last-child{
        margin: auto;
      }
    }
  }
  div.originators{
    //h4{
    //  margin-top: 0;
    //  font-weight: normal;
    //}
    div.heading-text-container {
      width: 100%;
    }
    > div{
      text-align: center;
      //font-weight: bold;
    }
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div.youtube_video{
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }
  div#decor{
    margin-top: 1em;
    text-align: center;
  }
  @include media($layout-breakpoint-xs, $layout-breakpoint-sm) {
    width: 85%;
    padding: 0;
    .member-item {
      .member-container {
        .description {
          min-height: 132px;
        }
      }
    }
    ul.volunteers {
      li {
        text-align: center;
      }
    }
  }
  @include media-max($layout-breakpoint-xxs){
    width: 85%;
    padding: 0;
    div.heading-text-container {
      div.heading{
        font-size: 26px;
        padding: 0 14px;
      }
    }
    .founders-section {
      .member-item {
        width: 100%;
      }
    }
    .section-seperator{
      .seperator-text{
        padding: 0 14px;
        font-size: 26px;
      }
    }
    ul.goals{
      padding-right: 20px;
    }
    ul.volunteers{
      li {
        flex-basis: 100%;
      }
    }
    div.youtube_video{
      margin-top: 70px;
    }
  }
  @include media($layout-breakpoint-xxs, $layout-breakpoint-sm){
    width: 85%;
    padding: 0;
    .founders-section {
      .member-item {
        width: 45%;
        .member-container {
          .description {
            min-height: 132px;
          }
        }
      }
    }
  }
}

