@import '../../assets/css/_colors.scss';

div.terms-and-conditions-wrapper {
  background-color: $bg-white;
  padding: 15px;
  > div.list,
  > div.mid-text,
  > div.top-text,
  h3 {
    font-size: 15px;
  }
  div.top-text {
    margin-top: 50px;
    h3 {
      font-weight: bold;
      text-decoration: underline;
      text-align: center;
    }
  }
  div.list {
    ol {
      list-style-type: none;
      padding-right: 22px;
      li {
        position: relative;
        p {
          span.number {
            position: absolute;
            top: 0;
            right: -20px;
          }
          strong.item-title {
            text-decoration: underline;
            padding-left: 0.3em;
          }
          a{
            font-size: 17px;
            color: rgb(34, 165, 185);
            text-decoration: underline;
          }
        }
      }
    }
  }
}