@import '../../assets/css/colors';

div.helpi-button-container {
  .helpi-button {
    background-color: $primary-button;
    color: #ffffff;
    font-family: "Yed";
    font-weight: lighter;
    border-radius: 2px;
    border: 0;
    cursor: pointer;

    &.btn-primary {
      height: 30px;
      font-size: 17px;
      min-width: 130px;
    }

    &.btn-secondary {
      height: 24px;
      font-size: 14px;
      min-width: 130px;
    }
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    button:not([disabled]):hover & {
      background-color: $primary-color;
    }

    &.case-register-btn {
      width: 140px;
      margin-right: 3px;
    }

    &.bold {
      font-weight: 600;
    }

    &.thick {
      padding: 11px 20px;

    }

    &.filter-search-btn {
      border-radius: 3px;
      min-width: 88px;
      font-size: 14px;
      font-weight: 500;
      padding: 0 6px;
      height: 35px;
    }

    &.more-cases {
      font-size: 20px;
      padding: 10px 15px;
    }

    &.big {
      width: 250px;
      height: 60px;
      border-radius: 4px;
      font-size: 22px;
    }

    &.fb-button {
      background-color: $fb-blue;
      position: relative;
      padding-right: calc(6px + 40px + 8px);
      font-size: 18px;

      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background-image: url('../../assets/img/login/FACEBOOK.svg');
        top: calc(50% - 20px);
        position: absolute;
        right: 8px;
      }
    }

    &.user-log-in {
      height: 60px;
    }

    &.orange {
      background-color: var(--secondary-color);
    }

  }
}