@import "../../assets/css/colors";

div.input-wrapper-container {
  label {
    right: 0;
  }
  div[data-react-toolbox="input"].auth-input {
    width: 430px;
    font-family: 'Yed';

    input {
      height: 44px;
      border-bottom: 1px solid $border-gray-green;
      &:focus:not([disabled]):not([readonly]) {
        ~ label:not(.GRQEP) {
          color: var(--primary-color);
        }
      }
      ~ label {
        color: var(--primary-color);
        top: 6px;
        font-size: 18px !important;
      }
    }
  }

}