@charset "UTF-8";
.section-seperator {
  width: 100%;
  border-bottom: 1px solid #A9DCE3;
  height: 20px;
  text-align: center; }

footer {
  width: 100%;
  background-color: #565656;
  position: relative; }
  footer.login-route {
    display: none; }
  footer .footer-container {
    max-width: 80%;
    margin: auto;
    padding: 20px 0;
    font-size: 14px; }
  footer .sponsor-container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    footer .sponsor-container .text {
      color: #fff;
      display: flex;
      justify-content: flex-end;
      margin-bottom: -15px;
      padding-left: 5px;
      direction: rtl; }
    footer .sponsor-container img {
      max-width: 400px; }
  footer .joinus-container {
    max-width: 400px;
    width: 400px;
    color: white;
    direction: rtl;
    text-align: center; }
    footer .joinus-container div.follow-text {
      margin-bottom: 10px; }
    footer .joinus-container a.facebook {
      text-decoration: none;
      color: inherit;
      display: flex;
      flex-direction: column;
      align-items: center; }
      footer .joinus-container a.facebook img {
        width: 50px;
        height: 50px; }
      footer .joinus-container a.facebook div.helpiFacebook {
        margin-top: 5px; }
  footer .social-links {
    height: 100px;
    max-width: 75%;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }
  footer .footer-links {
    margin: 20px auto;
    width: 626px;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center; }
    footer .footer-links span.link, footer .footer-links li.link {
      cursor: pointer;
      color: white;
      line-height: 11px; }
      footer .footer-links span.link:after, footer .footer-links li.link:after {
        content: '▪';
        color: white;
        margin: 0 0.7em; }
      footer .footer-links span.link:last-child:after, footer .footer-links li.link:last-child:after {
        content: none; }
      footer .footer-links span.link a, footer .footer-links li.link a {
        text-decoration: none;
        color: white; }
  footer div.tav {
    position: absolute;
    height: 90%;
    top: 16px;
    left: 13px; }
    footer div.tav img {
      display: block;
      height: 100%;
      width: auto; }

@media (max-width: 1199px) {
  footer .social-links {
    max-width: 100%;
    flex: 1 1 50%; }
  footer .joinus-container {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 50%; } }

@media (max-width: 1024px) {
  footer .footer-links {
    margin: 20px auto;
    width: 100%;
    flex-direction: column; }
    footer .footer-links li.link {
      margin-bottom: 0.7em; }
      footer .footer-links li.link:after {
        content: none; }
  footer div.tav {
    height: 51%;
    top: 148px;
    left: 148px; } }

@media (max-width: 599px) {
  footer .social-links {
    height: auto;
    flex-flow: row wrap; }
    footer .social-links .sponsor-container, footer .social-links .joinus-container {
      flex-basis: 100%; }
    footer .social-links .sponsor-container {
      display: flex;
      flex-flow: column;
      align-items: center; }
      footer .social-links .sponsor-container .text {
        margin-bottom: 1em; }
      footer .social-links .sponsor-container img {
        max-width: 100%; }
    footer .social-links .joinus-container {
      align-items: center;
      margin-top: 2em; }
  footer div.tav {
    height: 40%;
    top: 185px;
    left: 25px; } }
