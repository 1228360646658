div.text-area-input-container {
  flex: 1;
  display: flex;
  div[data-react-toolbox="input"] {
    font-family: 'Yed', 'arial';
    display: inline-block;
    position: relative;
    padding: 2px;
    margin: 0;
    margin-top: 18px;
    margin-left: 7em;
    vertical-align: middle;
    flex: 1;
    overflow: visible !important;
    &.errored {
      textarea {
        border: 1px solid #de3226;
      }
      .bottomBar {
        &:before, &:after {
          background-color: #de3226;
        }
      }
    }
    textarea {
      height: 190px;
      z-index: auto;
      position: relative;
      line-height: 26px;
      font-size: 16px;
      transition-duration: 0s;
      transition-timing-function: ease;
      transition-property: none;
      background: transparent;
      border: 1px solid #6f6f6f;
      resize: none;
      &:focus:not([disabled]):not([readonly]) {
        border: none;
      }
      &:focus:not([disabled]):not([readonly]), &.notEmpty {
        & ~ label.descriptionLabel:not(.GRQEP) {
          top: -13px;
        }
      }
    }
    .bottomBar {
      &:before, &:after {
        background-color: #4d90fe;
      }
    }
    label.descriptionLabel {
      top: 10px;
      right: 10px;
      color: #6f6f6f !important;
    }
    span.errorLabel {
      font-size: 15px;
    }
  }
}