@keyframes popIn {
  0% {
    top: -100%;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

aside.scroll-banner {
  position: sticky;
  z-index: 9999;
  opacity: 0;

  &.show {
    animation: popIn 0.5s;
  }
}