$primary-color : #1ABCC5;
$primary-button: #{$primary-color};
$secondary-color : #F47B20;
$company-case-frame: #F7821d;
$company-case-frame-opacity: rgba(247, 130, 29, 0.8);
$label-color : #444444;
$header-bg: #565656;
$light-sky-blue: #defbf9;
$bg-white: #fafafa;
$faded-text: #a6a6a6;
$heading-line-blue: #a9dce3;
$fb-blue: #537cb6;
$border-gray-green: #48797F;
$bright-blue: #00bcd4;
$picture-list-heading-blue: #22a4b7;
$error-message: #e90303;
$text-color: #666666;
$whitelabel-bg-blue: #39b1c6;
$header-bg: #ffffff