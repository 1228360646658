@import "../../assets/css/variables";
@import '../../assets/css/_colors.scss';
@import '../../assets/css/_responsive.scss';

div.auth-box {
  form.basic-details-form,
  form.social-worker-form {
    flex-flow: row wrap;
  }

  form.social-worker-form {
    max-width: 855px;
    justify-content: center;
    div.validated-input-container.auth-input {
      width: 400px;
      &:first-of-type {
        margin-left: 55px;
        margin-bottom: 0;
      }
      &:nth-of-type(3) {
        margin: 20px auto;
        //flex-basis: 100%;
      }
    }
    div.helpi-button-container {
      flex-basis: 100%;
      text-align: center;
    }
  }

  form.sign-up-form {
    div.validate-icon{
      color: green;
      position: absolute;
      top: 40%;
      right: 103%;
    }
    div.under-fields {
      justify-content: flex-end;
    }
  }
  @include media-max($layout-breakpoint-md) {
    form.basic-details-form,
    form.social-worker-form {
      flex-flow: column nowrap;
      div.outsider:nth-child(3),
      div.validated-input-container.auth-input:first-child {
        margin-left: 0;
      }
    }

    form.social-worker-form {
      div.validated-input-container.auth-input {
        &:nth-of-type(3) {
          margin-top: 0;
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
  //padding: 30px;
  //border: 1px solid $white-three;
}
