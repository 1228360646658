@import "../../../assets/css/variables";
@import "../../../assets/css/colors";

section.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$header-height} - #{$footer-height-desktop});
  h1 {
    color: var(--primary-color);
  }
  div.helpi-button-container {
    button.helpi-button {
      height: auto;
    }
  }

  a {
    text-decoration: underline;
    color: var(--primary-color);
    font-size: 14px;
    margin-top: 10px;
  }
}